import React from 'react';

export default props => (
	<g>
		<path className="line" d="M27,16c0,1.489-0.292,2.913-0.874,4.269c-0.581,1.354-1.366,2.526-2.349,3.509s-2.154,1.768-3.509,2.349
	C18.913,26.708,17.489,27,16,27c-1.642,0-3.204-0.346-4.684-1.038c-1.48-0.693-2.741-1.669-3.781-2.93
	c-0.067-0.095-0.098-0.202-0.093-0.322s0.045-0.216,0.122-0.293l1.962-1.977c0.096-0.086,0.215-0.129,0.358-0.129
	c0.152,0.02,0.263,0.077,0.329,0.172c0.697,0.907,1.552,1.609,2.564,2.105c1.012,0.495,2.086,0.744,3.223,0.744
	c0.994,0,1.94-0.193,2.843-0.58s1.684-0.909,2.342-1.568c0.659-0.658,1.182-1.439,1.568-2.342s0.58-1.849,0.58-2.843
	c0-0.993-0.193-1.941-0.58-2.843s-0.909-1.683-1.568-2.342c-0.658-0.659-1.439-1.182-2.342-1.568S16.994,8.667,16,8.667
	c-0.936,0-1.833,0.17-2.693,0.509s-1.623,0.823-2.292,1.454l1.962,1.977c0.296,0.287,0.363,0.616,0.2,0.988
	c-0.162,0.382-0.444,0.573-0.845,0.573H5.917c-0.248,0-0.463-0.091-0.645-0.272C5.091,13.713,5,13.499,5,13.25V6.833
	c0-0.401,0.191-0.683,0.573-0.845c0.373-0.163,0.702-0.095,0.988,0.201l1.862,1.848c1.021-0.965,2.189-1.712,3.502-2.242
	C13.238,5.265,14.596,5,16,5c1.489,0,2.913,0.292,4.269,0.874c1.354,0.583,2.526,1.366,3.509,2.349s1.768,2.153,2.349,3.509
	C26.708,13.087,27,14.51,27,16z"/>
	</g>
);

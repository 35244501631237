import React from 'react';

export default props => (
	<g>
		<path className="line" d="M5.382,26.619l1.641-5.399l0.059-0.059l15.781-15.78l3.757,3.758L10.78,24.977L5.382,26.619z M7.597,21.562
		l-1.242,4.083l4.082-1.242L25.703,9.139l-2.841-2.842L7.597,21.562z"/>
		<rect className="line" x="6.628" y="22.713" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -0.9941 45.6625)" width="4.664" height="0.649"/>
		<polygon className="line" points="7.99,25.49 5.968,26.031 6.509,24.009"/>
	</g>
);

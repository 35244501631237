const defaultMapView = {
	center: {
		lat: 50.099577,
		lon: 14.425960
	},
	boxRange: 10000000,
	tilt: 0,
	roll: 0,
	heading: 0
};

export default {
	defaultMapView
}
import React from 'react';

export default props => (
	<g>
		<path className="line" d="M16,13.308c0.192,0,0.361,0.073,0.509,0.221L26.83,23.851c0.147,0.146,0.222,0.316,0.222,0.509
	c0,0.191-0.074,0.362-0.222,0.509l-1.107,1.107c-0.148,0.147-0.316,0.222-0.509,0.222s-0.362-0.074-0.51-0.222L16,17.271
	l-8.704,8.704c-0.147,0.147-0.317,0.222-0.509,0.222s-0.362-0.074-0.509-0.222L5.17,24.868c-0.148-0.146-0.222-0.317-0.222-0.509
	c0-0.192,0.074-0.362,0.222-0.509l10.32-10.322C15.639,13.381,15.808,13.308,16,13.308z M16,4.803c0.192,0,0.361,0.073,0.509,0.222
	L26.83,15.346c0.147,0.146,0.222,0.316,0.222,0.509c0,0.191-0.074,0.362-0.222,0.51l-1.107,1.107
	c-0.148,0.146-0.316,0.221-0.509,0.221s-0.362-0.074-0.51-0.221L16,8.767l-8.704,8.705c-0.147,0.146-0.317,0.221-0.509,0.221
	s-0.362-0.074-0.509-0.221L5.17,16.364c-0.148-0.147-0.222-0.318-0.222-0.51c0-0.192,0.074-0.362,0.222-0.509l10.32-10.321
	C15.639,4.876,15.808,4.803,16,4.803z"/>
	</g>
);
import React from 'react';

export default props => (
	<g>
		<path className="polygon" d="M8 8 l0 18 l16 0 l0 -18" strokeWidth="2" stroke="black" strokeLinejoin="round" fill="transparent"/>
		<path className="line" d="M5 8 l22 0" strokeWidth="2" stroke="black" strokeLinecap="round"/>
		<path className="line" d="M14 12 l0 10" strokeWidth="2" stroke="black" strokeLinecap="round"/>
		<path className="line" d="M18 12 l0 10" strokeWidth="2" stroke="black" strokeLinecap="round"/>
		<path className="polygon" d="M10 8 l3 -4 l6 0 l3 4" strokeWidth="2" stroke="black" strokeLinejoin="round" fill="transparent"/>
	</g>
);
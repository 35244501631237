import React from 'react';

export default props => (
	<g>
		<path className="line" d="M13.308,16c0-0.192,0.073-0.361,0.221-0.509L23.851,5.17c0.146-0.147,0.316-0.222,0.509-0.222
	c0.191,0,0.362,0.074,0.509,0.222l1.107,1.107c0.147,0.148,0.222,0.316,0.222,0.509s-0.074,0.362-0.222,0.51L17.271,16l8.704,8.704
	c0.147,0.147,0.222,0.317,0.222,0.51c0,0.191-0.074,0.361-0.222,0.509l-1.107,1.107c-0.146,0.147-0.317,0.222-0.509,0.222
	c-0.192,0-0.362-0.074-0.509-0.222L13.528,16.51C13.381,16.361,13.308,16.192,13.308,16z M4.803,16c0-0.192,0.073-0.361,0.222-0.509
	L15.346,5.17c0.146-0.147,0.316-0.222,0.509-0.222c0.191,0,0.362,0.074,0.51,0.222l1.107,1.107c0.146,0.148,0.221,0.316,0.221,0.509
	s-0.074,0.362-0.221,0.51L8.767,16l8.705,8.704c0.146,0.147,0.221,0.317,0.221,0.51c0,0.191-0.074,0.361-0.221,0.509l-1.107,1.107
	c-0.147,0.147-0.318,0.222-0.51,0.222c-0.192,0-0.362-0.074-0.509-0.222L5.024,16.51C4.876,16.361,4.803,16.192,4.803,16z"/>
	</g>
);
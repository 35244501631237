import React from 'react';

export default props => (
	<g>
		<path className="line" d="M16.983,3.272l7.351,22.055c0.139,0.398,0.055,0.75-0.252,1.057c-0.037,0.038-0.104,0.089-0.193,0.148
	c-0.209,0.13-0.431,0.182-0.667,0.149c-0.237-0.029-0.44-0.131-0.609-0.298l-6.617-6.616l-6.617,6.616
	c-0.168,0.167-0.371,0.269-0.609,0.298c-0.237,0.032-0.459-0.02-0.666-0.149c-0.207-0.13-0.352-0.306-0.437-0.528
	c-0.084-0.222-0.087-0.446-0.011-0.677l7.352-22.055c0.045-0.153,0.131-0.292,0.253-0.414c0.207-0.206,0.452-0.306,0.735-0.298
	c0.223-0.008,0.425,0.054,0.609,0.184C16.788,2.874,16.915,3.05,16.983,3.272z"/>
	</g>
);

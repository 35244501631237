import React from 'react';

export default props => (
	<g>
		<path d="M5 5 l0 17 l5 0 l0 -12 l12 0 l0 -5" strokeWidth="2" className="polygonFilled"/>
		<path d="M10 22 l0 5 l17 0 l0 -17 l-5 0 l0 12 l-12 0" strokeWidth="2" className="polygon"/>
		<path d="M10 10 L22 22" strokeWidth="2" className="line"/>
		<path d="M16 10 L22 16" strokeWidth="2" className="line"/>
		<path d="M10 16 L16 22" strokeWidth="2" className="line"/>
	</g>
);
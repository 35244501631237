import React from 'react';

export default props => (
	<g>
		<path className="line" d="M 10,25 A 3.5,3.5 0 0 1 6.5,28.5 3.5,3.5 0 0 1 3,25 3.5,3.5 0 0 1 6.5,21.5 3.5,3.5 0 0 1 10,25 Z"/>
		<path className="line" d="m 4,12.5 h 5 c 0.554,0 1,0.446 1,1 v 5 c 0,0.554 -0.446,1 -1,1 H 4 c -0.554,0 -1,-0.446 -1,-1 v -5 c 0,-0.554 0.446,-1 1,-1 z"/>
		<path className="line" d="M 6.5488281,4.0214844 C 6.3688189,4.0168495 6.200219,4.1093556 6.1074219,4.2636719 l -3,4.9785156 C 2.9071078,9.5751818 3.1465554,9.9994087 3.5351562,10 h 6 C 9.9237571,9.9994087 10.163205,9.5751818 9.9628906,9.2421875 l -3,-4.9785156 C 6.8751262,4.1176904 6.7190891,4.0264234 6.5488281,4.0214844 Z M 8.6503906,9 V 9 H 4.4199219 Z"/>
		<path className="line" d="m 14.080078,6 a 1.0001,1.0001 0 1 0 0,2 h 13.853516 a 1.0001,1.0001 0 1 0 0,-2 z"/>
		<path className="line" d="m 14.080078,15 a 1.0001,1.0001 0 1 0 0,2 h 13.853516 a 1.0001,1.0001 0 1 0 0,-2 z"/>
		<path className="line" d="m 14.080078,24 a 1.0001,1.0001 0 1 0 0,2 h 13.853516 a 1.0001,1.0001 0 1 0 0,-2 z"/>
	</g>
);
import React from 'react';

export default props => (
<g transform="matrix(0.05117622,0,0,0.05117622,2.9443694,-1.5263058)">
	<path className="line" d="m 224.595,201.822 h -93.576 c -6.879,0 -13.674,3.236 -15.211,7.307 l -24.902,66.025 c -2.188,5.797 2.867,10.61 11.34,10.61 h 115.271 c 8.477,0 15.584,-4.813 15.873,-10.611 l 3.295,-66.024 c 0.203,-4.072 -5.211,-7.307 -12.09,-7.307 z"/>
	<path className="line" d="m 379.204,201.821 -93.574,0.001 c -6.879,0 -12.293,3.236 -12.092,7.307 l 3.293,66.024 c 0.291,5.798 7.4,10.611 15.873,10.611 h 115.275 c 8.473,0 13.525,-4.813 11.338,-10.611 l -24.902,-66.024 c -1.536,-4.072 -8.334,-7.308 -15.211,-7.308 z"/>
	<path className="line" d="M 419.61,319.696 H 295.565 c -9.117,0 -16.227,5.703 -15.865,12.927 l 6.236,125.035 c 0.586,11.742 10.93,21.594 23.078,21.594 h 165.289 c 12.148,0 18.283,-9.852 13.855,-21.594 L 440.994,332.623 c -2.722,-7.225 -12.267,-12.927 -21.384,-12.927 z"/>
	<path className="line" d="m 214.657,319.696 -124.043,10e-4 c -9.121,0 -18.662,5.702 -21.387,12.926 L 22.065,457.657 c -4.428,11.742 1.703,21.594 13.855,21.594 h 165.285 c 12.152,0 22.492,-9.852 23.076,-21.594 l 6.24,-125.035 c 0.363,-7.223 -6.745,-12.926 -15.864,-12.926 z"/>
</g>
);

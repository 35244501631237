import React from 'react';

export default props => (
	<g>
		<circle className="line" cx="6.998" cy="16" r="3.15"/>
		<circle className="line" cx="24.005" cy="8.513" r="3.149"/>
		<circle className="line" cx="24.005" cy="23.484" r="3.149"/>
		<path className="line" d="M19.623,10.034c-0.003-0.005-0.002-0.012-0.004-0.018c-0.003-0.005-0.008-0.009-0.012-0.014
	c-0.042-0.082-0.111-0.149-0.206-0.184l0,0l-0.264-0.096L16.36,8.711c-0.199-0.072-0.418,0.03-0.492,0.229l-0.095,0.265
	c-0.073,0.2,0.029,0.418,0.229,0.49l1.724,0.627l-6.156,2.87c-0.192,0.089-0.275,0.317-0.185,0.509l0.119,0.255
	c0.09,0.192,0.317,0.275,0.509,0.185l6.155-2.87l-0.627,1.722c-0.072,0.199,0.029,0.418,0.229,0.491l0.264,0.096
	c0.199,0.072,0.418-0.03,0.492-0.229l1.01-2.778l0.097-0.263V10.31C19.665,10.216,19.658,10.119,19.623,10.034z"/>
		<path className="line" d="M19.623,21.83c-0.003,0.006-0.002,0.013-0.004,0.018c-0.003,0.006-0.008,0.01-0.012,0.015
	c-0.042,0.081-0.111,0.149-0.206,0.185l0,0l-0.264,0.096l-2.777,1.012c-0.199,0.071-0.418-0.031-0.492-0.229l-0.095-0.265
	c-0.073-0.199,0.029-0.418,0.229-0.49l1.724-0.627l-6.156-2.871c-0.192-0.089-0.275-0.316-0.185-0.508l0.119-0.256
	c0.09-0.191,0.317-0.274,0.509-0.185l6.155,2.87l-0.627-1.723c-0.072-0.199,0.029-0.418,0.229-0.49l0.264-0.097
	c0.199-0.071,0.418,0.03,0.492,0.229l1.01,2.777l0.097,0.264l0,0C19.665,21.648,19.658,21.746,19.623,21.83z"/>
	</g>
);
